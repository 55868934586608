.Emitida {
    width: 10px; /* Adjust width as needed */
    height: 10px; /* Adjust height as needed */
    background-color: #46c51a;
    border-radius: 50%; /* Creates a circle */
    display: inline-block; /* Ensures the element acts like text inline */
    margin-top: 6px;
  }

  .Cotizada{
    width: 10px; /* Adjust width as needed */
    height: 10px; /* Adjust height as needed */
    background-color: #4dc5f0;
    border-radius: 50%; /* Creates a circle */
    display: inline-block; /* Ensures the element acts like text inline */
    margin-top: 6px;
  }
  .Cancelada{
    width: 10px; /* Adjust width as needed */
    height: 10px; /* Adjust height as needed */
    background-color: #ef2f24;
    border-radius: 50%; /* Creates a circle */
    display: inline-block; /* Ensures the element acts like text inline */
    margin-top: 6px;
  }
  .Pendiente{
    width: 10px; /* Adjust width as needed */
    height: 10px; /* Adjust height as needed */
    background-color: #efd718;
    border-radius: 50%; /* Creates a circle */
    display: inline-block; /* Ensures the element acts like text inline */
    margin-top: 6px;
  }
  .Expirada{
    width: 10px; /* Adjust width as needed */
    height: 10px; /* Adjust height as needed */
    background-color: #f0972b;
    border-radius: 50%; /* Creates a circle */
    display: inline-block; /* Ensures the element acts like text inline */
    margin-top: 6px;
  }
  .centerTitle{
    color: #3A56A1;
    /* Heading/H4/Medium */
    margin: 0 auto;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 16px;
  }
  .box{
    border-radius: 8px;
    box-shadow: 0px 0px 12px 0px rgba(204, 204, 204, 0.20);
    padding: 20px;
    background-color: #fff;
  }
