.MuiBox-root{
    /*height: 90% !important;*/
    /*width: auto !important;*/
}
.link{
    text-decoration: underline;
    color: rgb(0, 106, 198);
    cursor: pointer;
}

.css-7ldjcq-MuiGrid-root>.MuiGrid-item{
    padding-left: 0px  !important; 
}

.CardBox2 p{
    overflow: hidden ; 
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 18px;
    font-weight: 100;
}